@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
	padding: 0;
	margin: 0;
	outline: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
}

:root {
	--blue: #1976D2;
	--grey: #b2b2b2;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-thumb {
	background-color: var(--blue);
}

body {
	background-color: white;
}

.container {
	padding: 0 96px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 48px 0 96px 0;
}

.content {
	flex: 1;
	align-items: center;
	justify-content: space-between;
}

form .input-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}
form .input-wrapper .input {
	width: 100%;
	margin: 8px;
}
form .input-wrapper .button {
	margin-right: 8px;
}

.dropzone {
	border: 1.2px dashed var(--grey) !important;
	background: none !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 960px) {
	.container {
		padding: 0 24px;
	}
}

@media only screen and (max-width: 480px) {
	form .input-wrapper {
		flex-direction: column;
		align-items: flex-end;
	}
}