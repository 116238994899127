.partners .filters {
	display: flex;
	flex-direction: row;
}
.partners .filters .selects {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.partners .field {
	width: 100%;
}
.partners .term {
	margin: 10px 10px 10px 0;
}
.partners .cities {
	margin: 10px 10px 10px 10px;
}
.partners .businesses {
	margin: 10px 0 10px 10px;
}

.partners .content {
	margin-top: 48px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}
.partners .content::after {
	content: '';
}

.partners .create {
	position: fixed;
	right: 96px;
	bottom: 64px;
}

@media only screen and (max-width: 960px) {
	.partners .filters {
		flex-direction: column;
	}
	.partners .cities {
		margin: 10px 10px 10px 0;
	}
	.partners .create {
		right: 24px;
		bottom: 24px;
	}
}

@media only screen and (max-width: 480px) {
	.partners .filters .selects {
		flex-direction: column;
	}
	.partners .filters .businesses {
		margin: 10px 0;
	}
}