@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
* {
	padding: 0;
	margin: 0;
	outline: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
}

:root {
	--blue: #1976D2;
	--grey: #b2b2b2;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-thumb {
	background-color: #1976D2;
	background-color: var(--blue);
}

body {
	background-color: white;
}

.container {
	padding: 0 96px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.pagination {
	display: flex;
	justify-content: center;
	margin: 48px 0 96px 0;
}

.content {
	flex: 1 1;
	align-items: center;
	justify-content: space-between;
}

form .input-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}
form .input-wrapper .input {
	width: 100%;
	margin: 8px;
}
form .input-wrapper .button {
	margin-right: 8px;
}

.dropzone {
	border: 1.2px dashed #b2b2b2 !important;
	border: 1.2px dashed var(--grey) !important;
	background: none !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 960px) {
	.container {
		padding: 0 24px;
	}
}

@media only screen and (max-width: 480px) {
	form .input-wrapper {
		flex-direction: column;
		align-items: flex-end;
	}
}
.login {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.login form {
	width: 100%;
	max-width: 480px;
	display: flex;
	flex-direction: column;
}
.partners .filters {
	display: flex;
	flex-direction: row;
}
.partners .filters .selects {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.partners .field {
	width: 100%;
}
.partners .term {
	margin: 10px 10px 10px 0;
}
.partners .cities {
	margin: 10px 10px 10px 10px;
}
.partners .businesses {
	margin: 10px 0 10px 10px;
}

.partners .content {
	margin-top: 48px;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}
.partners .content::after {
	content: '';
}

.partners .create {
	position: fixed;
	right: 96px;
	bottom: 64px;
}

@media only screen and (max-width: 960px) {
	.partners .filters {
		flex-direction: column;
	}
	.partners .cities {
		margin: 10px 10px 10px 0;
	}
	.partners .create {
		right: 24px;
		bottom: 24px;
	}
}

@media only screen and (max-width: 480px) {
	.partners .filters .selects {
		flex-direction: column;
	}
	.partners .filters .businesses {
		margin: 10px 0;
	}
}
.partner {
	width: 100%;
	max-width: 300px;
	display: flex;
	margin: 8px 0;
	padding: 10px;
	border-radius: 4px;
}
.partner .avatar {
	flex-shrink: 0;
	width: 64px;
	height: 64px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;
}
.partner .info {
	margin-left: 16px;
}
.partner .name {
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 960px) {
	.partner {
		margin: 8px;
	}
}

@media only screen and (max-width: 480px) {
	.partner {
		padding: 0;
		margin: 8px 0;
	}
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 32px 0 16px 0;
}
.new-partner .content {
	padding: 24px 0;
}
.new-partner .active-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
