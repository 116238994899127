.partner {
	width: 100%;
	max-width: 300px;
	display: flex;
	margin: 8px 0;
	padding: 10px;
	border-radius: 4px;
}
.partner .avatar {
	flex-shrink: 0;
	width: 64px;
	height: 64px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;
}
.partner .info {
	margin-left: 16px;
}
.partner .name {
	overflow: hidden;
	text-overflow: ellipsis;
}

@media only screen and (max-width: 960px) {
	.partner {
		margin: 8px;
	}
}

@media only screen and (max-width: 480px) {
	.partner {
		padding: 0;
		margin: 8px 0;
	}
}