.login {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.login form {
	width: 100%;
	max-width: 480px;
	display: flex;
	flex-direction: column;
}